<template>
   <v-app>
    <header>
      <b-navbar toggleable="lg" type="light" class="bg-primary2 main-nav animated shadow-lg slideInDown" fixed="top" >
        <b-container>
          <b-navbar-brand @click="$router.push('/')">
            <Logo></Logo>
          </b-navbar-brand>
               <span  style="color: #fff; font-size: 14px;" v-if="isMobile && loginStatus">
                  <template v-if="user.perfiles[0].usuario_perfil_id == 2">
                    MANAGER REGIONAL -
                  </template>
                  <template v-else-if="user.perfiles[0].usuario_perfil_id == 13">
                    LÍDER -
                  </template>
                  <template v-else-if="user.perfiles[0].usuario_perfil_id == 5">
                    USUARIO -
                  </template>
                  <template v-else-if="user.perfiles[0].usuario_perfil_id == 10">
                    TRADE MARKETING -
                  </template>
                  <template v-else-if="user.perfiles[0].usuario_perfil_id == 16">
                    AGENCIA: {{user.activa}} -
                  </template>  
                  {{user.mrt}}
              </span>       
              <button class="btn btn-md  btn-light text-primary-2 " @click="cerrarSesion()" v-if="isMobile && loginStatus">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            <b-collapse id="nav-text-collapse" is-nav v-if="loginStatus">
              <b-navbar-nav class="m-lg-auto"> 
              </b-navbar-nav>
              <b-navbar-nav right>
                <b-nav-item>
                  <span  style="color: #fff;">
                      <template v-if="user.perfiles[0].usuario_perfil_id == 2">
                        MANAGER REGIONAL -
                      </template>
                      <template v-else-if="user.perfiles[0].usuario_perfil_id == 13">
                        LÍDER -
                      </template>
                      <template v-else-if="user.perfiles[0].usuario_perfil_id == 5">
                        USUARIO -
                      </template>
                      <template v-else-if="user.perfiles[0].usuario_perfil_id == 10">
                        TRADE MARKETING -
                      </template>
                      <template v-else-if="user.perfiles[0].usuario_perfil_id == 16">
                        AGENCIA: {{user.activa}} -
                      </template>  
                     {{user.nombre}}
                  </span>      
                     <button class="btn btn-md my-2 my-sm-0 btn-light text-primary-2 ml-2">
                       <i class="far fa-user"></i>  
                    </button>
                    <button class="btn btn-md my-2 my-sm-0 btn-light text-primary-2 ml-2" @click="cerrarSesion()">
                      <i class="fas fa-sign-out-alt"></i>
                    </button>
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
        </b-container>
      </b-navbar>
      <!-- <NavbarPF v-if="loggin == 1" :usuario="usuario"></NavbarPF> -->
    </header>
    <v-main :style="isMobile ? 'z-index: 24; margin-top:40px;' : 'z-index: 24; margin-top:50px;'">       
        <router-view />
    </v-main>
   </v-app>
</template>

<script>
import Logo from './components/Logo.vue';
import Cookies from "js-cookie";
export default {
  components: { Logo },
  name: 'App',
  data(){
    return{
      isMobile: this.$mobile()
    }
  },
  computed: {
    loginStatus() {
      return this.$store.getters.getLoginStatus
    },
    user() {
      return this.$store.getters.getInfoUser;
    },
    perfil() {
      return this.$store.getters.getCanal
    },
  },
  methods:{
    cerrarSesion(){
        this.$store.commit(
            "setLoginStatus",
            false
        );
        this.$store.commit(
            "setInfoUser",
            null
        );    
        Cookies.remove("session_id");
        location.reload();
    },
  } 
};
</script>
<style scoped>
.bg-primary2{
  background: #039CF3;
}
</style>